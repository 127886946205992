const theme = {
  colors: {
    primary: '#0070f3'
  },
  space: [
    // margin and padding
    0, 4, 8, 10, 16, 18, 20, 25, 32, 36, 48, 64, 72, 80, 96, 128, 256
  ]
}

export default theme
