import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import 'antd/dist/antd.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'utils/internationalization/i18n'
import store, { persistor } from 'app/store'
import GlobalStyle from 'app/globalStyle'
import theme from 'app/theme'

const App = ({ Component, pageProps }) => {
  return (
    <React.Fragment>
      <h1 style={{ display: 'none' }}>Flavorwiki</h1>

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalStyle />
          <ThemeProvider theme={theme}>
            <Component {...pageProps} />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </React.Fragment>
  )
}

export default App
